<template>
  <div>
    <Title
      :title="item.title"
      :criteria="item.criteria"
      :title-icon="item.titleIcon"
      v-on="$listeners" />
    <CollapsedRows
      :test-value="item.dataTest"
      :title="item.title"
      :items="item.dataArr"
      :is-show-action-icon="isShowActionIcon"
      :criteria="item.criteria"
      @click-action-menu="$emit('click-action-menu', {
        ...$event,
        criteria: item.criteria
      })"
      @onIntersect="$emit('onIntersect', {
        ...$event,
        criteria: item.criteria
      })"
      @handleItemClick="$emit('handleItemClick', {
        ...$event,
        criteria: item.criteria
      })" />
  </div>
</template>
<script>
export default {
  name: 'CollectionsSearchGroupItems',
  components: {
    CollapsedRows: () => import('@/components/Collections/CollectionsSearch/CollectionsSearchCollapsedRows'),
    Title: () => import('@/components/Collections/CollectionsSearch/CollectionsSearchTitle'),
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isShowActionIcon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
